/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import "./templates/porto/styles/app.css";
import "./templates/porto/css/custom.css";
import a2lix_lib from "@a2lix/symfony-collection/dist/a2lix_sf_collection.min";
import autoComplete from "@tarekraafat/autocomplete.js";
a2lix_lib.sfCollection.init();
window.autoComplete = autoComplete;

import "htmx.org";
window.htmx = require("htmx.org");

import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";


$(function () {

    const swiper = new Swiper('.mySwiper', {
        slidesPerView: 1,
       
        autoHeight: true ,
        loop: true ,

        pagination: { el: '.banner-list-pagination', dynamicBullets: true, dynamicMainBullets: 2, clickable: true, },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        breakpoints: {
           320: {
                slidesPerView: 1,
               
            },
          640: {
            slidesPerView: 1,
           
          },
          1024: {
            slidesPerView: 1,
          
          }
        },
        on: {
            slideChangeTransitionStart: function () {
             //   if (window.innerWidth < 640) {
                    imgSwiper.slideTo(this.activeIndex);
             //   }
            },
        },
    })


    var imgSwiper = new Swiper('.swip-img .swiper-container', {
        speed: 1000,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        }
    })
    var textSwiper = new Swiper('.swip-list .swiper-container', {
        slidesPerView: 7,
        speed: 1000,
        pagination: { el: '.swip-list-pagination', dynamicBullets: true, dynamicMainBullets: 2, clickable: true, },
        breakpoints: {
           320: {
                slidesPerView: 1,
               
            },
          640: {
            slidesPerView: 1,
           
          },
          1024: {
            slidesPerView: 7,
          
          }
        },
        on: {
            slideChangeTransitionStart: function () {
                if (window.innerWidth < 640) {
                    imgSwiper.slideTo(this.activeIndex);
                }
            },
        },
    })
    if (window.innerWidth > 640) {
        $('.swip-list .swiper-slide').hover(function () {
            $(this).find('.swip-text').stop().slideDown(300).parents('.swiper-slide').siblings().find('.swip-text').stop().slideUp(300);
            imgSwiper.slideTo($(this).index());
        })

        $('.banner-list .swiper-slide').hover(function () {
            $(this).find('.swip-text').stop().slideDown(300).parents('.swiper-slide').siblings().find('.swip-text').stop().slideUp(300);
            imgSwiper.slideTo($(this).index());
        })
    }else{
        $(".swip-text").css("display","block");
    }
});
if (window.innerWidth >= 680) {
    $(".swip-list .swiper-slide").addClass("wow flipInX").attr("data-wow-delay",'0.3s');
   
} else {
    // $(".demo").addClass("wow fadeInUp").attr("data-wow-delay",'0.3s');
}

$('.ddl-href').change(function ($e){
    var link = $(this).val();
    window.location.href = link;
});
// console.log("test2")
// start the Stimulus application
// import './bootstrap';
